import _, { get } from "lodash";
import * as ACTION_TYPE from "./constants";
import * as ORDER_ACTION_TYPE from "../order/constants";
import { LOCATION_CHANGE } from "../../utils/constants";
import { setParams, getParams } from "../../utils/location";
import {
  getGiftRelevantBranchesIds,
  isMoneyCoupon,
  getCoupon,
  isGlobalDiscount,
} from "../../utils/gifts";

const initialState = {
  isDuringGiftRedeem: false,
  isDuringDealRedeem: false,
  headerTitle: null,
  selectedGift: null,
  selectedDeal: null,
  shouldNavigateTo: null,
  steps: [],
  pickAnotherLocationOrDismiss: false,
  currentStepIndex: -1,
  startedWithFindLocation: false,
};

export const STEP = {
  SELECT_DEAL_OPTION: "SELECT_DEAL_OPTION",
  SELECT_ITEM_TO_BUY: "SELECT_ITEM_TO_BUY",
  CUSTOMIZE_ITEM_TO_BUY: "CUSTOMIZE_ITEM_TO_BUY",
  SELECT_ITEM_YOU_GET_WITH_DISCOUNT: "SELECT_ITEM_YOU_GET_WITH_DISCOUNT",
  CUSTOMIZE_ITEM_YOU_GET_WITH_DISCOUNT: "CUSTOMIZE_ITEM_YOU_GET_WITH_DISCOUNT",
  MONEY_GIFT: "MONEY_GIFT",
  GLOBAL_DISCOUNT_GIFT: "GLOBAL_DISCOUNT_GIFT",
};

const getStepsForGift = (gift, menuData) => {
  if (!gift){
    return {};
  }
  const coupon = getCoupon(gift);
  const { posItemIdToMenuItemId } = gift || {};

  const { couponValue } = coupon;

  const itemsWithSizes = _.filter(
    _.flatMap(menuData, "items"),
    "sizeOptionsIds",
  );
  const wrapWithSizesCategoryIfNeeded = (menuItemId) => {
    const menuItemWithSizes = _.find(itemsWithSizes, (itemWithSizes) =>
      _.includes(itemWithSizes.sizeOptionsIds, menuItemId),
    );
    if (menuItemWithSizes) {
      return { menuItemId: menuItemWithSizes.id, sizes: menuItemId };
    } else {
      return { menuItemId };
    }
  };

  const menuItemsIdsToBuy =
    couponValue.condition &&
    couponValue.condition.type === "PURCHASED_ITEMS" &&
    !_.isEmpty(couponValue.condition.purchasedItems)
      ? _.map(
          _.flatten(
            _.uniq(
              _.compact(
                _.flatten(
                  _.map(couponValue.condition.purchasedItems, ({ posItemId }) =>
                    _.uniq(posItemIdToMenuItemId[posItemId]),
                  ),
                ),
              ),
            ),
          ),
          wrapWithSizesCategoryIfNeeded,
        )
      : [];

  const offeredMenuItemsIds = !_.isEmpty(couponValue.offeredItems)
    ? _.map(
        _.flatten(
          _.uniq(
            _.compact(
              _.flatten(
                _.map(couponValue.offeredItems, ({ posItemId }) =>
                  _.uniq(posItemIdToMenuItemId[posItemId]),
                ),
              ),
            ),
          ),
        ),
        wrapWithSizesCategoryIfNeeded,
      )
    : [];

  const isWithSizes = (menuItemId) => Boolean(_.get(menuItemId, "sizes"));

  const steps = [
    menuItemsIdsToBuy.length > 1 && {
      type: STEP.SELECT_ITEM_TO_BUY,
      context: {
        menuItems: menuItemsIdsToBuy,
        pathname: "/gift",
      },
    },
    menuItemsIdsToBuy.length && {
      type: STEP.CUSTOMIZE_ITEM_TO_BUY,
      context:
        menuItemsIdsToBuy.length === 1
          ? {
              ...menuItemsIdsToBuy[0],
              pathname: `/order/items/${menuItemsIdsToBuy[0].menuItemId}`,
            }
          : null,
    },
    offeredMenuItemsIds.length > 1 && {
      type: STEP.SELECT_ITEM_YOU_GET_WITH_DISCOUNT,
      context: { menuItems: offeredMenuItemsIds, pathname: "/gift" },
    },
    offeredMenuItemsIds.length && {
      type: STEP.CUSTOMIZE_ITEM_YOU_GET_WITH_DISCOUNT,
      context:
        offeredMenuItemsIds.length === 1
          ? {
              ...offeredMenuItemsIds[0],
              pathname: `/order/items/${offeredMenuItemsIds[0].menuItemId}`,
            }
          : null,
    },
  ];

  const optimizedSteps = _.map(_.compact(steps), (step, index) => ({
    ...step,
    index: index,
  }));

  if (isMoneyCoupon(coupon) && _.isEmpty(optimizedSteps)) {
    return [
      {
        type: STEP.MONEY_GIFT,
        context: { pathname: "/gift" },
        index: 0,
      },
    ];
  } else if (isGlobalDiscount(coupon) && _.isEmpty(optimizedSteps)) {
    return [
      {
        type: STEP.GLOBAL_DISCOUNT_GIFT,
        context: { pathname: "/gift" },
        index: 0,
      },
    ];
  } else {
    return optimizedSteps;
  }
};

let flattendMenuItems = null;
let menuData = null;

const getStepsForDealAndBranchId = (deal, updatedMenuData) => {
  menuData = updatedMenuData || menuData;
  console.log({ menuData, deal });
  if (deal.discountRuleType === "group") {
    // if this is a deal group the first step is to choose the deal option
    return [
      {
        type: STEP.SELECT_DEAL_OPTION,
        context: { pathname: `/deals/${deal.id}` },
      },
    ];
  }

  flattendMenuItems = _.isEmpty(flattendMenuItems)
    ? _.flatMap(menuData, "items")
    : flattendMenuItems;

  const posItemIdToMenuItemId = _.isPlainObject(deal.posItemIdToMenuItemId)
    ? _.cloneDeep(deal.posItemIdToMenuItemId)
    : _.isString(deal.posItemIdToMenuItemId)
    ? JSON.parse(deal.posItemIdToMenuItemId)
    : {};

  const dealMenuItemsIds = _.uniq(_.flatten(_.values(posItemIdToMenuItemId)));

  const dealMenuItems = _.filter(
    flattendMenuItems,
    (menuItem) =>
      _.includes(dealMenuItemsIds, menuItem.id) ||
      !_.isEmpty(_.intersection(menuItem.sizeOptionsIds, dealMenuItemsIds)),
  );
  if (_.get(deal.percentageParams, "discountItems") && _.get(deal, "discountRuleType") === "percentage") {
    _.set(
      deal,
      "itemsCombinationQuery.[0]",
      deal.percentageParams.discountItems,
    );
  }
  const menuItemsCombinationQuery = _.map(
    deal.itemsCombinationQuery,
    ({ posItemIds }) => {
      const stepMenuItemIds = _.compact(
        _.uniq(
          _.flatten(
            _.map(posItemIds, (posItemId) => posItemIdToMenuItemId[posItemId]),
          ),
        ),
      );

      const stepMenuItems = {};

      _.forEach(dealMenuItems, (menuItem) => {
        if (_.includes(stepMenuItemIds, menuItem.id)) {
          stepMenuItems[menuItem.id] = menuItem;
        } else if (
          !_.isEmpty(_.intersection(menuItem.sizeOptionsIds, stepMenuItemIds))
        ) {
          stepMenuItems[menuItem.id] = {
            ...menuItem,
            sizes: _.uniq(
              _.intersection(menuItem.sizeOptionsIds, stepMenuItemIds),
            ),
          };
        }
      });

      return _.values(stepMenuItems);
    },
  );

  const steps = _.map(
    _.flatten(
      _.map(
        menuItemsCombinationQuery,
        (stepMenuItemsCombinationQuery, index) => {
          console.log({ stepMenuItemsCombinationQuery });
          const subSteps = [];

          const stepPosItemIds = _.get(
            deal.itemsCombinationQuery[index],
            "posItemIds",
          );
          if (_.includes(stepMenuItemsCombinationQuery, undefined)) {
            console.error(
              `Deal item/step number ${index +
                1} is not connected to a menu item`,
            );
          }

          if (stepMenuItemsCombinationQuery.length > 1) {
            // user have to first select an Item.
            subSteps.push({
              type: STEP.SELECT_ITEM_TO_BUY,
              context: {
                menuItemsIds: _.map(
                  _.compact(stepMenuItemsCombinationQuery),
                  "id",
                ),
                menuItems: _.map(
                  _.compact(stepMenuItemsCombinationQuery),
                  (item) => ({ ...item, menuItemId: item.id }),
                ),
                stepPosItemIds,
                pathname: `/deals/${deal.id}`,
              },
            });
          }

          subSteps.push({
            type: STEP.CUSTOMIZE_ITEM_TO_BUY,
            context:
              stepMenuItemsCombinationQuery.length === 1
                ? {
                    menuItemId: stepMenuItemsCombinationQuery[0].id,
                    stepPosItemIds,
                    pathname: `/order/items/${
                      stepMenuItemsCombinationQuery[0].id
                    }`,
                    sizes: stepMenuItemsCombinationQuery[0].sizes,
                  }
                : null,
          });
          return subSteps;
        },
      ),
    ),
    (step, index) => ({ ...step, index }),
  );

  return steps;
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case 'REDUX_STORAGE_LOAD':
    //   return state;

    case ACTION_TYPE.SELECT_GIFT:
      const steps = getStepsForGift(
        action.payload.gift,
        action.payload.menuData,
      );
      if (steps.length === 0) {
        console.error("Something is wrong with this gift...", action.payload);
        return initialState;
      }
      const nextTargetPath = setParams(steps[0]?.context.pathname, {
        stepIndex: 0,
      });
      console.log(nextTargetPath);
      if (
        !action.payload.selectedBranchId ||
        !action.payload.selectedServingOptionType
      ) {
        const shouldSkipServingOptionPage =  get(action.payload.business, "appStyles.shouldChooseServingOptionFromModal") ||
          get(action.payload.business, "appStyles.skipServingOptionsPage");
        
        return {
          ...state,
          isDuringGiftRedeem: true,
          selectedGift: action.payload.gift,
          shouldNavigateTo: shouldSkipServingOptionPage ? "/find-location":"/serving-options",
          currentStepIndex: -2,
          pickAnotherLocationOrDismiss: false,
          steps,
          selectedBranchTarget: nextTargetPath,
          startedWithFindLocation: true,
        };
      }
      const relevantBranchIds = getGiftRelevantBranchesIds(action.payload.gift);
      // in case that the user already picked a location but this gift is not available in this location
      if (
        !_.isEmpty(relevantBranchIds) &&
        !_.includes(relevantBranchIds, action.payload.selectedBranchId)
      ) {
        return {
          ...state,
          isDuringGiftRedeem: true,
          selectedGift: action.payload.gift,
          shouldNavigateTo: null,
          pickAnotherLocationOrDismiss: true,
          steps,
          selectedBranchTarget: nextTargetPath,
          startedWithFindLocation: true,
        };
      }
      return {
        ...state,
        isDuringGiftRedeem: true,
        selectedGift: action.payload.gift,
        shouldNavigateTo: nextTargetPath,
        pickAnotherLocationOrDismiss: false,
        steps,
        selectedBranchTarget: null,
        startedWithFindLocation: false,
      };

    case ACTION_TYPE.RESET_GIFT_REDEEM:
      return initialState;

    case ORDER_ACTION_TYPE.SET_BRANCH_ID_AND_NEXT_AVAILABLE_TIME:
      if (!state.selectedGift) {
        return state;
      }
      return {
        ...state,
        shouldNavigateTo: null,
        pickAnotherLocationOrDismiss: false,
      };
    case ORDER_ACTION_TYPE.SET_SERVING_OPTION:
      if (!state.selectedGift) {
        return state;
      }
      return {
        ...state,
        shouldNavigateTo: "/find-location",
        pickAnotherLocationOrDismiss: false,
        currentStepIndex: -1,
      };
    case LOCATION_CHANGE:
      if (
        _.startsWith(action.payload.pathname, "/order") &&
        !_.startsWith(action.payload.pathname, "/order/items")
      ) {
        return { ...initialState };
      }
      if (_.startsWith(action.payload.pathname, "/gifts")) {
        return {
          ...initialState,
          isDuringGiftRedeem: true,
          headerTitle: "Redeem a Reward or Share",
        };
      }

      if (
        state.isDuringGiftRedeem &&
        _.startsWith(action.payload.pathname, "/serving-options") &&
        state.currentStepIndex === -1
      ) {
        return {
          ...state,
          headerTitle: "Choose how to get your reward",
          shouldNavigateTo: "/serving-options",
          currentStepIndex: -2,
        };
      }

      if (
        state.isDuringGiftRedeem &&
        _.startsWith(action.payload.pathname, "/serving-options") &&
        state.currentStepIndex === -2
      ) {
        return {
          ...state,
          headerTitle: "Choose how to get your reward",
          shouldNavigateTo: "/serving-options",
        };
      }
      if (
        state.currentStepIndex <= -1 &&
        state.isDuringGiftRedeem &&
        _.startsWith(action.payload.pathname, "/find-location")
      ) {
        return { ...state, headerTitle: "Choose where to get your reward" };
      }

      const { stepIndex: paramsStepIndex } = getParams(action.payload);

      if (
        (state.isDuringGiftRedeem || state.isDuringDealRedeem) &&
        paramsStepIndex &&
        !_.isEmpty(state.steps) &&
        state.currentStepIndex > 0
      ) {
        if (
          state.currentStepIndex - 1 == paramsStepIndex &&
          state.steps[state.currentStepIndex - 1].context &&
          state.steps[state.currentStepIndex].context &&
          _.startsWith(
            action.payload.pathname,
            state.steps[state.currentStepIndex - 1].context.pathname,
          )
        ) {
          console.log("back step -");
          // empty current step.
          return {
            ...state,
            headerTitle: null,
            currentStepIndex: state.currentStepIndex - 1,
          };
        }
        if (
          state.currentStepIndex + 1 == paramsStepIndex &&
          state.steps[state.currentStepIndex + 1] &&
          state.steps[state.currentStepIndex + 1].context &&
          _.startsWith(
            action.payload.pathname,
            state.steps[state.currentStepIndex + 1].context.pathname,
          )
        ) {
          console.log("advancing steps");
          return {
            ...state,
            headerTitle: null,
            currentStepIndex: state.currentStepIndex + 1,
          };
        }
      }
      if (
        (state.isDuringGiftRedeem || state.isDuringDealRedeem) &&
        !_.isEmpty(state.steps) &&
        state.currentStepIndex > 0 &&
        (paramsStepIndex
          ? state.currentStepIndex - 1 === paramsStepIndex
          : true) &&
        state.steps[state.currentStepIndex - 1].context &&
        state.steps[state.currentStepIndex].context &&
        _.startsWith(
          action.payload.pathname,
          state.steps[state.currentStepIndex - 1].context.pathname,
        )
      ) {
        console.log("back step");
        // empty current step.
        return {
          ...state,
          headerTitle: null,
          currentStepIndex: state.currentStepIndex - 1,
        };
      }

      if (
        (state.isDuringGiftRedeem || state.isDuringDealRedeem) &&
        !_.isEmpty(state.steps) &&
        state.steps[state.currentStepIndex + 1] &&
        state.steps[state.currentStepIndex + 1].context &&
        _.startsWith(
          action.payload.pathname,
          state.steps[state.currentStepIndex + 1].context.pathname,
        )
      ) {
        console.log("advancing steps");
        return {
          ...state,
          headerTitle: null,
          currentStepIndex: state.currentStepIndex + 1,
        };
      }

      if (
        (state.isDuringGiftRedeem || state.isDuringDealRedeem) &&
        !_.isEmpty(state.steps) &&
        state.steps[state.currentStepIndex] &&
        state.steps[state.currentStepIndex].context &&
        _.startsWith(
          action.payload.pathname,
          state.steps[state.currentStepIndex].context.pathname,
        )
      ) {
        console.log("refreshing page");
        return state;
      }

      return initialState;

    case ACTION_TYPE.SAVE_GIFT_STEP:
      if (state.currentStepIndex > -1 && state.steps[state.currentStepIndex]) {
        const clonedState = _.cloneDeep(state);
        clonedState.steps[clonedState.currentStepIndex].context = {
          ...clonedState.steps[clonedState.currentStepIndex].context,
          ...action.payload,
        };

        if (action.payload.selectedItem) {
          if (clonedState.isDuringGiftRedeem) {
            const currentStep = clonedState.steps[clonedState.currentStepIndex];
            const sizes = _.flatMap(
              _.filter(currentStep.context.menuItems, {
                menuItemId: currentStep.context.selectedItem.id,
              }),
              "sizes",
            );

            clonedState.steps[clonedState.currentStepIndex + 1].context = {
              ...clonedState.steps[clonedState.currentStepIndex + 1].context,
              menuItemId: action.payload.selectedItem.id,
              sizes,
              pathname: `/order/items/${action.payload.selectedItem.id}`,
              // fill ready state
            };
          } else if (
            clonedState.isDuringDealRedeem &&
            clonedState.selectedDeal &&
            clonedState.selectedDeal.discountRuleType === "group" &&
            clonedState.steps[clonedState.currentStepIndex].type !== STEP.SELECT_ITEM_TO_BUY
          ) {
            clonedState.steps = [
              clonedState.steps[0],
              ...getStepsForDealAndBranchId(
                action.payload.selectedItem,
                action.payload.menuData,
              ),
            ];
            clonedState.shouldNavigateTo = clonedState.steps[1].pathname;
          } else if (
            clonedState.isDuringDealRedeem &&
            clonedState.steps[clonedState.currentStepIndex].type === STEP.SELECT_ITEM_TO_BUY
          ) {
            const currentStep = clonedState.steps[clonedState.currentStepIndex];

            const sizes = _.get(
              _.find(currentStep.context.menuItems, {
                menuItemId: action.payload.selectedItem.id,
              }),
              "sizes",
            );

            clonedState.steps[clonedState.currentStepIndex + 1].context = {
              ...clonedState.steps[clonedState.currentStepIndex + 1].context,
              menuItemId: action.payload.selectedItem.id,
              sizes,
              stepPosItemIds: currentStep.context.stepPosItemIds,

              pathname: `/order/items/${action.payload.selectedItem.id}`,
              // fill ready state
            };
          }
        }
        return clonedState;
      }
      return state;

    case ACTION_TYPE.SELECT_DEAL:
      const dealSteps = getStepsForDealAndBranchId(
        action.payload.deal,
        action.payload.menuData,
      );

      if (_.isEmpty(dealSteps)) {
        return initialState;
      }
      return {
        ...state,
        isDuringDealRedeem: true,
        selectedGift: null,
        selectedDeal: action.payload.deal,
        shouldNavigateTo: _.get(dealSteps, "[0].context.pathname"),
        pickAnotherLocationOrDismiss: false,
        steps: dealSteps,
        selectedItemToBuy: null,
        selectedItemToGetWithDiscount: null,
        selectedBranchTarget: null,
        startedWithFindLocation: false,
      };

    default:
      return state;
  }
};
