import classnames from 'classnames';
import debounce from 'lodash/debounce';
import React from 'react';
import BagIcon from '../icons/Bag.svg';
import * as styles from './index.module.scss';

export default class BagItemsIndicator extends React.Component {
  state = { shouldBounce: false };

  bounceDuration = 1000;
  clearBounceTimeoutRef = null;

  clearBounce = debounce(() => this.setState({ shouldBounce: false }), 200);

  componentWillUnmount() {
    clearTimeout(this.clearBounceTimeoutRef);
  }

  bounce = () => {
    this.setState({ shouldBounce: true }, () => {
      clearTimeout(this.clearBounceTimeoutRef);
      this.clearBounceTimeoutRef = setTimeout(this.clearBounce, this.bounceDuration);
    });
  };
  render() {
    const { counter, appStyles } = this.props;

    return (
      <span
        className={classnames(
          styles.BagItemsIndicator,
          this.state.shouldBounce && styles.Bounce,
          appStyles.rtl && styles.RTL
        )}
        style={{ animationDuration: `${this.bounceDuration}ms` }}
        aria-label={`${counter} items`}
      >
        <BagIcon className={styles.BagIcon} />
        {counter > 0 && (
          <span
            className={styles.Counter}
            style={{
              backgroundColor: appStyles.counterIndicatorBackground || '#fff',
              color: appStyles.actionColor || '#555',
              fontSize: counter < 10 ? '0.69rem' : '0.5625rem',
              textShadow: 'none',
            }}
          >
            {counter}
          </span>
        )}
      </span>
    );
  }
}
